import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import TestimonialSlider from "../components/sliders/sap-testimonial-slider/sap-testimonial-slider"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

const SuccessStories = ({ data }) => {
  const images = {
    successBg: convertToBgImage(getImage(data.successBg)),
    sapBg: convertToBgImage(getImage(data.sapBg)),
  }

  const testimonialsImages = {
    mangala: getImage(data.mangala),
    gerard: getImage(data.gerard),
    lalith: getImage(data.lalith),
    samuel: getImage(data.samuel),
    viraj: getImage(data.viraj),
    minhaj: getImage(data.minhaj),
    gayan: getImage(data.gayan),
    vidunath: getImage(data.vidunath),
  }
  const testimoniallogo = {
    vertiv: getImage(data.vertiv),
    sap4hana: getImage(data.sap4hana),
    sapbussiness: getImage(data.sapbussiness),
    sapsuccess: getImage(data.sapsuccess),
  }

  const testimonial_slider = [
    {
      index: 0,
      image: testimonialsImages.mangala,
      logo: testimoniallogo.sap4hana,
      name: "Mangala Wickramasinghe",
      position: "Group Chief Information Officer",
      company: "Diesel & motor Engineering PLC",
      description:
        "As a Group, we transitioned into industries including Medical Equipment and Engineering while our existing systems and processes were primarily aligned to the Automotive industry. This was the main reason behind our need for a tier one ERP solution such as SAP, that could function across industries. We selected Tech Pacific after much consideration, and still remain grateful for the choice. They were outstanding in terms of understanding our requirements and supporting us in translating that into ERP solutions. Tech Pacific exceeded all expectations in visualising the digital transformation for the entire group",
    },
    {
      index: 1,
      image: testimonialsImages.gerard,

      logo: testimoniallogo.sapbussiness,
      name: "Gerard Suares",
      position: "Chief Financial Officer",
      company: "W.K.V. Hydro Technics (Pvt) Ltd",
      description:
        "Our association with Tech Pacific dates back to their inception in 2008. Seldom does one find a partner who brings a precise vision to life; and Tech pacific has been consistent in this regard. They exceeded our expectations by carrying out the company’s digital transformation with seamless expertise, professionalism and timely delivery. It has been a pleasant journey, and we strongly recommend Tech Pacific to any business embarking on the progressive journey of digital transformation.",
    },
    {
      index: 2,
      image: testimonialsImages.lalith,
      name: "Lalith Kulasinghe",
      logo: testimoniallogo.sap4hana,
      position: "Chief Information Officer",
      company: "Colombo Fort Group Service PVT LTD",
      description:
        "Tech Pacific carried out the implementation of SAP S/4HANA ERP system for two of our companies; namely E.B Creasy Group and C.W Mackie group, both subsidiaries of Colombo Fort Land and Building PLC. A significant change that enabled both companies to have a centralized system for business operations back in 2016. We are currently working with the same team on upgrading to the latest S/4HANA 2020, with embedded FIORI for the next-generation workforce. Having worked with Tech pacific for half a decade, we have witnessed their technical competencies and customer oriented business strategy first hand. This has helped establish a partnership that goes beyond business, and we have no hesitation in recommending Tech Pacific.",
    },
    {
      index: 3,
      image: testimonialsImages.samuel,
      name: "Samuel Sathiyadaran",
      logo: testimoniallogo.vertiv,
      position: "Information Technology Consultant",
      company: "Alliance Finance Company PLC",
      description:
        "Our association with Tech Pacific started back in 2016, when we required maintenance for our Blade Center RACK. Since then, Tech pacific has carried out our maintenance with superior quality along with technical assistance offered at request. Their high standards in professionalism, quality, consistent on time delivery, and willingness to negotiate renewals is the reason behind Alliance Finance selecting Tech Pacific time and again.",
    },
    {
      index: 4,
      image: testimonialsImages.vidunath,
      name: "Vidunath Mathagaweera",
      logo: testimoniallogo.vertiv,
      position: "Assistant Manager",
      company: "FairFirst Insurance Ltd",
      description:
        "We chose Tech Pacific to be our partner on D/Emerson Smart Rack Servicing and maintenance in 2018, and working with their team has been quite the experience. They have an extremely professional technical team, consisting of highly skilled workers. Their attention to detail and on-time delivery have consistently stood out, and we will continue to lean on Tech Pacific for their services.",
    },
    {
      index: 2,
      image: testimonialsImages.viraj,
      name: "Viraj Liyanage",
      logo: testimoniallogo.vertiv,
      position: "Manager Systems Development",
      company: "MAGA ENGINEERING PVT LTD",
      description:
        "Tech Pacific was responsible for the successful installation of our Data Center Infrastructure back in 2015. What truly stood out to us was the support offered even after providing the solution. We would highly recommend Techpac for their superior products, after sales support and professional staff; having access to all levels of management when required was an unexpected value addition.",
    },
    {
      index: 5,
      image: testimonialsImages.minhaj,
      name: "Minhaj Hussain",
      logo: testimoniallogo.vertiv,
      position: "IT Manager",
      company: "Lakjaya Micro Finance LTD",
      description:
        "Our relationship with Tech Pacific began in 2018, with the installation of a Network Smartrack Solution. We were pleasantly surprised by their team of specialists equipped with strong technical expertise. Tech Pacific’s timely responsiveness in providing solutions to our problems was a pleasure only surpassed by their professionalism in customer care.",
    },
    {
      index: 6,
      image: testimonialsImages.gayan,
      name: "Gayan Perera",
      logo: testimoniallogo.vertiv,
      position: "Deputy Manager IT",
      company: "Siyapatha Finance PLC",
      description:
        "We began our journey with Tech Pacific back in 2016 when purchasing two Emerson Smart Server Cabinets. Our experience over five years has been consistently supportive.Mr. Farzan, Mr. Sasanka, Mr. Chanaka, and the entire team have surpassed our initial expectations. The after-sales service is unparalleled because they attend to breakdowns without delay irrespective of day and night. We would highly recommend Tech Pacific.",
    },
  ]

  return (
    <Layout>
      <Seo title="Success Stories | Tech Pacific Lanka" />
      <section>
        <div className="main">
          <BackgroundImage {...images.successBg} className="sap-header">
            <div className="container">
              <div className="sap-header-content">
                <div className="header-title success-title">
                  Success Stories
                </div>
                <div className="header-description">
                  Tech Pacific helps clients achieve success by understanding
                  the full scope of their needs and carefully matching them with
                  the right SAP software and mix of implementation services as
                  well as adaptations/customizations and training. These success
                  stories show how Tech Pacific can help you leverage SAP
                  solutions for successful Digital Transformation.
                </div>
                <div className="header-buttons header-buttons-success d-flex">
                  <Link to="/contact">
                    <button>Connect</button>
                  </Link>
                  <a href="tel:1234567890">
                    <button>Call Now</button>
                  </a>
                </div>
                <span>
                  {/* <div className="learn-more success">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div> */}
                </span>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="success-description-section">
          <div className="success-content container">
            <div className="success-title text-center">Success Stories</div>
            <div className="success-description text-center">
              Tech Pacific offers a wide range of SAP solutions for Digital
              Transformation, targeting multiple facets of a business. An
              offering tailored through bespoke service, it integrates digital
              technology into all areas of a business, resulting in fundamental
              operational changes.
            </div>
          </div>
        </div>
      </section>
      <section>
        <BackgroundImage
          {...images.sapBg}
          className="sap-testimonials about-testimonials"
        >
          <div className="container">
            <div className="sap-testimonials-title about-testimonials-title">
              What Clients Say
            </div>
            <Link to="/contact">
              <button>Talk to us</button>
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <div className="swiper-button-prev1"></div>
            <div className="testimonial-super-main">
              <div className="testimonial-item-main">
                <TestimonialSlider slides={testimonial_slider} />
              </div>
            </div>
            <div className="swiper-button-next1"></div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="recent-implementation">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="title">Recent Implementation</div>
              </div>
              <div className="col-md-6">
                <div className="items-section">
                  <div className="items d-flex align-items-center">
                    <span>SAP</span>
                    <span>Vertiv</span>
                    <div className="search-success">
                      <input
                        type="text"
                        placeholder="Search"
                        className="search-bar-dark"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="sap-grid-main">
          <div className="grid-row d-flex">
            <div className="grid-item blue">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/sap_bBD.png"
                      width={270}
                      alt="techpac"
                    />
                  </div>
                  <span className="sap-grid-title success-grid-title">
                    Redefining Travel
                  </span>
                  <span className="sap-grid-description">
                    Acorn Travels is an aviation and travel conglomerate in Sri
                    Lanka that provides comprehensive travel solutions for
                    corporate and leisure travel both globally and domestically.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <Link to="/success-stories/redefining-travel">
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </Link>
                  <Link to="/contact">
                    <button>Connect Now</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/Sap_su_fa.png"
                      width={280}
                      alt="techpac"
                    />
                  </div>
                  <span className="sap-grid-title success-grid-title">
                    Strategic HR
                  </span>
                  <span className="sap-grid-description">
                    Teejay Lanka is the largest textile group and knit fabric
                    provider in South Asia. Specializing in weft knitting, it is
                    regarded as Sri Lanka’s only multinational mill. Its
                    customer portfolio encompasses global brands such as Calvin
                    Klein and Victoria’s Secret.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <Link to="/success-stories/strategic-hr">
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </Link>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-row d-flex">
            <div className="grid-item">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/Sap4hana.png"
                      width={270}
                      alt="techpac"
                    />
                  </div>
                  <span className="sap-grid-title success-grid-title">
                    Achieving Business Agility
                  </span>
                  <span className="sap-grid-description">
                    United Motors is one of the most respected blue-chip
                    companies in Sri Lanka. It connects customers to innovative
                    automobile, machinery and related technologies and brands
                    from around the world.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <Link to="/success-stories/achieving-business-agility">
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </Link>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
            <div className="grid-item blue">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <div className="image">
                    <StaticImage
                      placeholder="blurred"
                      src="../images/home/slider-logos/sap_bBD.png"
                      width={270}
                      alt="techpac"
                    />
                  </div>
                  <span className="sap-grid-title success-grid-title">
                    Business Process Optimisation
                  </span>
                  <span className="sap-grid-description">
                    “Our latest work with Tech Pac was in 2018 with the SAP
                    Business ByDesign Implementation. They exceeded our
                    expectations by carrying out the W.K.V HydroTechnic’s
                    business process optimization with faultless expertise,
                    professionalism, and prompt delivery. We highly recommend
                    Tech Pacific to anyone embarking on a journey of digital
                    transformation”
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <Link to="/success-stories/business-process-optimisation">
                    <div className="learn-more sap-learn-more">
                      LEARN MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </Link>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="grid-row d-flex">
            <div className="grid-item blue">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title success-grid-title">
                    Lorem Ipsum
                  </span>
                  <span className="sap-grid-description">
                    Understand your customers and engage them with
                    hyper-personalized experiences. Give them the freedom to
                    explore and interact – and choose you.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <div className="learn-more sap-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title success-grid-title">
                    Lorem Ipsum
                  </span>
                  <span className="sap-grid-description">
                    Put employees at the heart of everything you do with SAP
                    SuccessFactors software. Our cloud HCM solutions are
                    completely designed around employee experiences – what they
                    need, how they work, and what motivates them.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <div className="learn-more sap-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="grid-row d-flex">
            <div className="grid-item">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title success-grid-title">
                    Lorem Ipsum
                  </span>
                  <span className="sap-grid-description">
                    The SAP Analytics Cloud solution combines BI, augmented and
                    predictive analytics, and planning capabilities into one
                    cloud environment. As the analytics layer of SAP’s Business
                    Technology Platform, it supports advanced analytics
                    enterprise-wide.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <div className="learn-more sap-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
            <div className="grid-item blue">
              <div className="grid-item-content">
                <div className="sap-grid-details">
                  <span className="sap-grid-title success-grid-title">
                    Lorem Ipsum
                  </span>
                  <span className="sap-grid-description">
                    It’s a strategy that shifts from traditional, disconnected
                    ways of managing spend to an integrated experience enabled
                    by intelligent technologies. Across channels and categories,
                    Intelligent Spend Management delivers a single, unified view
                    of your spend.
                  </span>
                </div>
                <div className="sap-grid-footer d-flex align-items-center">
                  <div className="learn-more sap-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                  <button>Connect Now</button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section>
        <div className="get-in-touch blue purple d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button
                className="btn btn-primary blue-contact-footer"
                type="button"
              >
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query SuccessStories {
    successBg: file(relativePath: { eq: "success-stories/successBg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sapBg: file(relativePath: { eq: "sap/sapBg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mangala: file(relativePath: { eq: "testimonials/mangala.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lalith: file(relativePath: { eq: "testimonials/lalith.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gerard: file(relativePath: { eq: "testimonials/gerard.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    vidunath: file(relativePath: { eq: "testimonials/vidunath.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    samuel: file(relativePath: { eq: "testimonials/samuel.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    viraj: file(relativePath: { eq: "testimonials/viraj.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    minhaj: file(relativePath: { eq: "testimonials/minhaj.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gayan: file(relativePath: { eq: "testimonials/gayan.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    vertiv: file(
      relativePath: { eq: "products/digital-infrastructure/LOGO.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sap4hana: file(relativePath: { eq: "home/slider-logos/Sap4hana.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sapbussiness: file(relativePath: { eq: "home/slider-logos/sap_bBD.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sapsuccess: file(relativePath: { eq: "home/slider-logos/Sap_su_fa.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default SuccessStories
